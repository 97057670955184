import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { RhTooltip } from "@design-system/components/RhTooltip/RhTooltip";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { offerRateAndEstimateTranslations } from "@portal/components/OfferRateAndEstimate/OfferRateAndEstimate.en.i18n";
import {
  OfferRateAndEstimateTooltipContainer,
  StyledFlexBoxColumn,
  StyledFlexBoxRow,
  StyledInformationIcon,
  StyledOfferCaption,
  StyledPerKWHWrap,
  StyledPrice,
} from "@portal/components/OfferRateAndEstimate/OfferRateAndEstimate.styled";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { useCallback } from "react";

interface OfferRateAndEstimateProps {
  hideSolarEligible?: boolean;
  hideTooltip?: boolean;
  inDialog?: boolean;
  offerSnapshot: OfferSnapshot;
}

export const OfferRateAndEstimate = ({
  offerSnapshot,
  inDialog = false,
  hideTooltip = false,
  hideSolarEligible = false,
}: OfferRateAndEstimateProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XXS));

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalSolarPlanShowAveragePrice } = featureFlagClient.getFlags([
    ["portalSolarPlanShowAveragePrice", false],
  ]);

  const { translate, translateJsx } = useTranslations();

  const {
    rhythmKwhRate,
    formattedCurrencyBaseCharge,
    solarCreditKwhRate,
    isTimeOfUse,
    price2000Kwh,
    isAutoPayPaperlessDiscountOffer,
    isShowEnergyRateOffer,
    isThermostatOffer,
  } = offerSnapshot;

  const {
    tOfferRateAndEstimatePerKWH,
    tOfferRateAndEstimateTimeOfUseTooltip,
    tOfferRateAndEstimatePerKilowattHour,
    tOfferRateAndEstimateAutopayPaperlessDiscountTooltip,
    tOfferRateAndEstimateTooltipLabel,
    tOfferRateAndEstimateAutopayPaperlessAndThermostatDiscountTooltip,
    tOfferRateAndEstimateThermostatDiscountTooltip,
  } = translate(offerRateAndEstimateTranslations);

  const { tOfferRateAndEstimateSolarEligible, tOfferRateAndEstimateBasedOn } =
    translateJsx({
      tOfferRateAndEstimateBasedOn: {
        amount: DEFAULT_AVERAGE_MONTHLY_USAGE,
      },
      tOfferRateAndEstimateSolarEligible: {
        baseCharge: `${formattedCurrencyBaseCharge}`,
        kwh: DEFAULT_AVERAGE_MONTHLY_USAGE,
        solarBuybackRate: formatDollarsToCents(solarCreditKwhRate),
        totalPerKwh: formatDollarsToCents(offerSnapshot.price2000Kwh),
      },
    });

  const isSolar = Boolean(offerSnapshot.solarEligible && !hideSolarEligible);
  const showEnergyRate =
    isSolar &&
    (isShowEnergyRateOffer || !portalSolarPlanShowAveragePrice.value);

  const tooltipText = useCallback(() => {
    const text: string[] = [];

    if (isTimeOfUse) {
      text.push(tOfferRateAndEstimateTimeOfUseTooltip);
    }

    if (isThermostatOffer && isAutoPayPaperlessDiscountOffer) {
      text.push(
        tOfferRateAndEstimateAutopayPaperlessAndThermostatDiscountTooltip
      );
    }

    if (isThermostatOffer && !isAutoPayPaperlessDiscountOffer) {
      text.push(tOfferRateAndEstimateThermostatDiscountTooltip);
    }

    if (isAutoPayPaperlessDiscountOffer && !isThermostatOffer) {
      text.push(tOfferRateAndEstimateAutopayPaperlessDiscountTooltip);
    }

    if (!isAutoPayPaperlessDiscountOffer && !isThermostatOffer) {
      text.push(tOfferRateAndEstimateTooltipLabel);
    }

    return text;
  }, [
    isAutoPayPaperlessDiscountOffer,
    isThermostatOffer,
    tOfferRateAndEstimateAutopayPaperlessAndThermostatDiscountTooltip,
    tOfferRateAndEstimateAutopayPaperlessDiscountTooltip,
    tOfferRateAndEstimateTooltipLabel,
    tOfferRateAndEstimateTimeOfUseTooltip,
    tOfferRateAndEstimateThermostatDiscountTooltip,
  ]);

  return (
    <StyledFlexBoxColumn $inDialog={inDialog}>
      <StyledFlexBoxRow>
        <StyledPrice>
          {showEnergyRate
            ? formatDollarsToCents(rhythmKwhRate)
            : formatDollarsToCents(price2000Kwh)}
          {inDialog && (
            <StyledPerKWHWrap>{tOfferRateAndEstimatePerKWH}</StyledPerKWHWrap>
          )}
        </StyledPrice>

        {(isTimeOfUse ||
          isAutoPayPaperlessDiscountOffer ||
          isThermostatOffer) &&
        !inDialog &&
        !hideTooltip ? (
          <RhTooltip
            ariaLabel={tOfferRateAndEstimateTooltipLabel}
            content={
              <OfferRateAndEstimateTooltipContainer>
                {tooltipText().map((text) => (
                  <span key={text}>{text}</span>
                ))}
              </OfferRateAndEstimateTooltipContainer>
            }
          >
            <StyledInformationIcon />
          </RhTooltip>
        ) : null}
      </StyledFlexBoxRow>
      {!showEnergyRate && !inDialog && (
        <StyledOfferCaption variant="body3" color="textSecondary">
          {isMobile
            ? tOfferRateAndEstimatePerKWH
            : tOfferRateAndEstimatePerKilowattHour}
        </StyledOfferCaption>
      )}
      <StyledOfferCaption
        color="textSecondary"
        variant={inDialog ? "body1" : "body3"}
      >
        {showEnergyRate
          ? tOfferRateAndEstimateSolarEligible
          : tOfferRateAndEstimateBasedOn}
      </StyledOfferCaption>
    </StyledFlexBoxColumn>
  );
};
