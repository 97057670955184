import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { yellow } from "@design-system/theme/palette.colors";
import { GroupedCompetitorPlans } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { RHYTHM } from "@portal/constants/misc.constant";
import { CompetitorNames } from "@portal/types/competitorTypes";
import { ChartData } from "chart.js";
import { DefaultTheme } from "styled-components";

interface BuildChartDataProps {
  competitorPlans: GroupedCompetitorPlans[];
  offerSnapshot: OfferSnapshot;
  theme: DefaultTheme;
}

export const buildChartData = (props: BuildChartDataProps) => {
  const { offerSnapshot, competitorPlans, theme } = props;
  const rhythmPlan = {
    averagePrice: offerSnapshot.displayPrice(),
    competitorName: RHYTHM as CompetitorNames,
    plans: [],
  } satisfies GroupedCompetitorPlans;

  return {
    datasets: [
      {
        backgroundColor: (context) => {
          const { chart } = context;

          const {
            ctx,
            chartArea,
            scales: { y },
          } = chart;

          // Rhythm is the first data in the list
          if (context.dataIndex === 0) {
            return theme.colors.primary.main;
          }

          if (!chartArea) {
            return theme.colors.grey[100];
          }

          const { top, bottom } = chartArea;

          const gradientSegment = ctx.createLinearGradient(0, bottom, 0, top);

          let border =
            (bottom - y.getPixelForValue(offerSnapshot.displayPrice())) /
            (bottom - top);

          if (border > 1) {
            border = 1;
          }
          gradientSegment.addColorStop(0, theme.colors.grey[100]);
          gradientSegment.addColorStop(border, theme.colors.grey[100]);
          gradientSegment.addColorStop(border, yellow.dark); // TODO: Replace with theme color
          gradientSegment.addColorStop(1, yellow.dark); // TODO: Replace with theme color

          return gradientSegment;
        },
        data: [rhythmPlan, ...competitorPlans],
        label: "Price",
        parsing: {
          xAxisKey: "competitorName",
          yAxisKey: "averagePrice",
        },
      },
    ],
    labels: [RHYTHM, ...competitorPlans.map((cp) => cp.competitorName)],
  } satisfies ChartData<"bar", GroupedCompetitorPlans[]>;
};
