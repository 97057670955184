import { getLabels } from "@common/components/EnergyUsageGraphV2/getLabels";
import { TemperatureLabelsType } from "@common/components/EnergyUsageGraphV2/types";
import {
  ChartDirection,
  ChartType,
  TemperatureType,
  UsageType,
} from "@common/types/usageTypes";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import {
  darkPurple,
  trueBlack,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { fontFamily } from "@design-system/theme/typography";
import {
  CartesianScaleTypeRegistry,
  ChartEvent,
  ChartOptions,
  LegendElement,
  LegendItem,
  PluginOptionsByType,
  ScaleOptionsByType,
  TimeUnit,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import dayjs from "dayjs";

const chartTypeMapping: Record<ChartType, TimeUnit> = {
  daily: "day",
  hourly: "hour",
  monthly: "month",
};

interface GenerateScalesProps {
  chartDirection: ChartDirection;
  chartType: ChartType;
  isMobile: boolean;
  temperatureAxisTitle: string;
  usageAxisTitle?: string;
  withTemperatures: boolean;
}

const generateScales = (props: GenerateScalesProps) => {
  const {
    chartType,
    chartDirection,
    isMobile,
    usageAxisTitle = "Usage (kWh)",
    temperatureAxisTitle,
    withTemperatures,
  } = props;

  const dateKey = chartDirection === "horizontal" ? "x" : "y";
  const usageKey = chartDirection === "horizontal" ? "y" : "x";
  const timeUnit = chartTypeMapping[chartType];

  const scales: _DeepPartialObject<{
    [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry>;
  }> = {
    [dateKey]: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      // reverse keys on vertical chart to put earliest time at the top
      reverse: chartDirection === "vertical",
      stacked: true,
      ticks: {
        autoSkip: true,
        maxTicksLimit: 8,
      },
      time: {
        unit: timeUnit,
      },
      type: "time",
    },
    [usageKey]: {
      beginAtZero: true,
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      stacked: true,
      ticks: {
        callback: (value, index, ticks) => {
          const absValue = Math.abs(Number(value));

          return `${absValue} kWh`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        color: "black",
        display: !isMobile,
        text: usageAxisTitle,
      },
      type: "linear",
    },
    weather: {
      beginAtZero: false,
      display: withTemperatures,
      position: "right",
      ticks: {
        callback: (value, index, ticks) => {
          return `${value}°F`;
        },
        font: {
          size: 10,
        },
      },
      title: {
        color: "black",
        display: !isMobile,
        text: temperatureAxisTitle,
      },
      type: "linear",
    },
  };

  return scales;
};

interface GenerateExtraPluginsProps {
  chartType: ChartType;
  isGeneration: boolean;
  withBoth: boolean;
  withTiers: boolean;
}
const generateExtraPlugins = (props: GenerateExtraPluginsProps) => {
  const { chartType, isGeneration, withBoth, withTiers } = props;

  const plugins: _DeepPartialObject<PluginOptionsByType<"bar">> = {};

  if (chartType === "hourly" && withTiers && !withBoth) {
    plugins.legend = {
      display: false,
      labels: {
        generateLabels: (chart) => getLabels(chart, isGeneration),
      },
      onClick: (
        e: ChartEvent,
        legendItem: LegendItem,
        legend: LegendElement<"bar">
      ) => {
        // Disable propagation for tiered hourly charts because there isn't anything to hide
        e.native?.stopPropagation();
      },
      position: "bottom",
    };
  }

  return plugins;
};

interface ChartOptionLabels {
  costTooltipLabel: string;
  dateHourTitle: string;
  dateTitle: string;
  defaultTierTitle: string;
  earnedTooltipLabel: string;
  generationTitle: string;
  generationTooltipLabel: string;
  temperatureLabels: TemperatureLabelsType;
  usageAxisTitle: string;
  usageTitle: string;
  usageTooltipLabel: string;
}

interface GetChartOptionsProps {
  chartDirection: ChartDirection;
  chartOptionsLabels: ChartOptionLabels;
  chartType: ChartType;
  currentTierNames?: string[];
  isMobile: boolean;
  latestAvailableDate: string;
  withConsumption: boolean;
  withEarned: boolean;
  withGeneration: boolean;
  withTemperatures?: boolean;
  withTiers: boolean;
}

export const getChartOptions = (props: GetChartOptionsProps) => {
  const {
    chartDirection,
    chartType,
    currentTierNames,
    withConsumption,
    withGeneration,
    withTiers,
    withEarned,
    chartOptionsLabels: {
      costTooltipLabel,
      dateHourTitle,
      dateTitle,
      defaultTierTitle,
      earnedTooltipLabel,
      generationTitle,
      generationTooltipLabel,
      temperatureLabels,
      usageAxisTitle,
      usageTooltipLabel,
    },
    latestAvailableDate,
    withTemperatures = false,
    isMobile,
  } = props;

  const indexAxis = chartDirection === "horizontal" ? "x" : "y";

  const interactionMode = chartDirection === "horizontal" ? "x" : "y";
  const withBoth = withConsumption && withGeneration;

  const options: ChartOptions<"bar" | "line"> = {
    indexAxis,
    interaction: {
      axis: indexAxis,
      intersect: false,
      mode: withTemperatures ? "nearest" : interactionMode,
    },
    maintainAspectRatio: false,

    plugins: {
      backgroundPlugin: !withTemperatures,
      legend: {
        display: false,
        labels: {
          filter: (item) => {
            if (!withBoth && withTiers && currentTierNames) {
              return currentTierNames.includes(item.text);
            }

            return true;
          },
        },
      },
      tooltip: {
        backgroundColor: trueWhite,
        bodyColor: trueBlack,
        bodyFont: {
          family: fontFamily,
          size: 12,
        },
        borderColor: darkPurple[500],
        borderWidth: 1,
        callbacks: {
          beforeBody: (context) => {
            const { datasetIndex, chart, raw } = context[0];
            const dataset = chart.data.datasets[datasetIndex];

            if (dataset.type === "line") {
              const temp = raw as TemperatureType;

              return [
                `${temperatureLabels.temperature}:`,
                `${temperatureLabels.average}: ${temp.average}°F`,
                `${temperatureLabels.high}: ${temp.high}°F`,
                `${temperatureLabels.low}: ${temp.low}°F`,
                "",
              ];
            }
          },
          footer: (tooltipItems) => {
            if (withBoth || !withTiers || chartType === ChartType.hourly) {
              return;
            }

            if (withConsumption) {
              let totalConsumptionKwh = 0;
              let totalConsumptionCost = 0;

              tooltipItems.forEach((item) => {
                const usage = item.raw as UsageType;

                totalConsumptionKwh += Number(usage.consumptionKwh ?? 0);
                totalConsumptionCost += Number(usage.consumptionCost ?? 0);
              });

              return [
                `Total: ${formatKwh(totalConsumptionKwh, 2)} / ${formatCurrency(
                  totalConsumptionCost
                )}`,
              ];
            }

            if (withGeneration) {
              let totalGenerationKwh = 0;
              let totalGenerationEarned = 0;

              tooltipItems.forEach((item) => {
                const usage = item.raw as UsageType;

                totalGenerationKwh += Number(usage.generationKwh);
                totalGenerationEarned += Number(usage.generationEarned);
              });

              return [
                `Total: ${formatKwh(totalGenerationKwh, 2)} / ${formatCurrency(
                  totalGenerationEarned
                )}`,
              ];
            }
          },
          label: (context) => {
            const { datasetIndex, chart } = context;
            const dataset = chart.data.datasets[datasetIndex];

            if (dataset.type === "line") {
              return "";
            }

            const raw = context.raw as UsageType;

            const isGenerationOnly = withGeneration && !withConsumption;

            const labels = [];

            // Only add a title if a tiered dataset and single graph
            if (withTiers && !withBoth) {
              labels.push(
                (isGenerationOnly
                  ? raw.generationTierName
                  : raw.consumptionTierName) ?? defaultTierTitle
              );
            }

            if (context.dataset.label === generationTitle || isGenerationOnly) {
              // Invert the data from how its displayed in the graph

              labels.push(
                `${generationTooltipLabel} ${formatKwh(
                  Number(raw.generationKwh) * -1,
                  2
                )}`
              );
              if (withEarned) {
                labels.push(
                  `${earnedTooltipLabel} ${formatCurrency(
                    raw.generationEarned,
                    2
                  )}`
                );
              }
            } else {
              labels.push(
                `${usageTooltipLabel} ${formatKwh(
                  Number(raw.consumptionKwh),
                  2
                )}`
              );

              labels.push(
                `${costTooltipLabel} ${formatCurrency(raw.consumptionCost, 2)}`
              );
            }

            // Add a extra line for spacing since padding can't be done between label items
            if (withTiers && chartType !== ChartType.hourly) {
              labels.push("");
            } else if (
              withGeneration &&
              context.dataset.label !== generationTitle
            ) {
              labels.push("");
            }

            return labels;
          },
          title: (context) => {
            // if in the same callback all values should be of the same datetime interval
            const cx = context[0];

            if (!dayjs(cx.label).isValid()) {
              // If for some reason the label isn't a datetime fall back to default label
              return undefined;
            }

            const titles: string[] = [];

            if (chartType === ChartType.hourly) {
              titles.push(dateHourTitle);
            } else {
              titles.push(dateTitle);
            }

            const dateTime = dayjs(cx.label);
            const day = dateTime.format("ddd, MMM D");

            if (chartType === ChartType.hourly) {
              const hours = `${dateTime.format("h:mm A")} - ${dateTime
                .add(1, "hour")
                .format("h:mm A")}`;

              titles.push(...[day, hours]);
            } else if (chartType === ChartType.monthly) {
              const endOfMonth = dateTime.add(1, "month").subtract(1, "day");

              // If the end of the month is after last available day, use last available as the end of range
              const endDateRange =
                dayjs(latestAvailableDate) < endOfMonth
                  ? dayjs(latestAvailableDate)
                  : endOfMonth;

              const dateRange = `${dateTime.format(
                "DD MMM"
              )} - ${endDateRange.format("DD MMM")}`;

              titles.push(dateRange);
            } else if (chartType === ChartType.daily) {
              titles.push(day);
            }

            return titles;
          },
        },
        caretSize: 10,
        displayColors: true,
        footerColor: trueBlack,
        footerFont: {
          family: fontFamily,
          size: 12,
        },
        padding: 18,
        position: "average",
        titleColor: trueBlack,
        titleFont: {
          family: fontFamily,
          size: 12,
          weight: "bold",
        },
        titleMarginBottom: 10,
        usePointStyle: true,
        yAlign: "center",
      },
      ...generateExtraPlugins({
        chartType,
        isGeneration: withGeneration && !withConsumption,
        withBoth,
        withTiers,
      }),
    },
    responsive: true,
    scales: generateScales({
      chartDirection,
      chartType,
      isMobile,
      temperatureAxisTitle: temperatureLabels.temperatureAxisTitle,
      usageAxisTitle,
      withTemperatures,
    }),
  };

  return options;
};
