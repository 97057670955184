import { RhRoundTabs } from "@design-system/components/RhRoundTabs/RhRoundTabs";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { purple } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import {
  PortalCard,
  PortalCardHeader,
} from "@portal/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const StyledDatePickerWrapper = styled.div`
  margin-right: auto;
  max-width: 150px;

  & .MuiInputBase-input {
    height: 30px;
    padding: 0 12px;
  }
`;

export const TabContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const StyledRhRoundTabs = styled(RhRoundTabs)`
  align-items: center;
  max-width: 100%;
`;

export const EnergyGraphContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const EnergyGraphTabsRadiosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 ${rhSpacingPx(2.5)} ${rhSpacingPx(2.5)};
`;

export const DateAndTotalsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-end;
  text-align: center;
`;

export const TemperaturesContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  margin-right: auto;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledTapGraphText = styled(RhTypography).attrs({
  fontWeight: FontWeight.Semibold,
  variant: "body2",
})`
  align-self: flex-end;
  color: ${purple.medium};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    display: none;
  }
`;

export const StyledEnergyUsageSectionCard = styled(PortalCard)`
  margin-bottom: ${rhSpacingPx(2.5)};
  padding: 0;
`;

export const StyledEnergyUsageSectionHeader = styled(PortalCardHeader)`
  margin: ${rhSpacingPx(2.5)} ${rhSpacingPx(2.5)} ${rhSpacingPx(2)};
`;

export const EnergyUsageGraphContainer = styled.div`
  padding: 0 ${rhSpacingPx(0.5)} ${rhSpacingPx(2.5)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    padding: 0 ${rhSpacingPx(2.5)} ${rhSpacingPx(2.5)};
  }
`;
