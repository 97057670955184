import { DUNS_DISPLAY_NAME_MAPPING } from "@common/constants/edi.constants";
import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import {
  formatCurrency,
  formatDollarsToCents,
  formatPhoneNumber,
  separateNumber,
} from "@common/utils/dataFormatters";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { PaymentMethodRetrieveType } from "@enroll-utils/types/paymentMethodTypes";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@portal/components/LegalDocuments/LegalDocumentLinks";
import { PriceTierSnapshotsSummary } from "@portal/components/PriceTierSnapshotsSummary/PriceTierSnapshotsSummary";
import { signUpSummaryInfoTranslations } from "@portal/components/SignUpSummaryInfo/SignUpSummaryInfo.en.i18n";
import {
  AutopayPaperlessDiscountContainer,
  ContractDocumentsContainer,
  StyledBillingPreference,
  StyledBillingPreferenceValue,
  StyledCell,
  StyledLabelContainer,
  StyledPlanDetail,
  StyledPlanDetailWrapper,
  StyledPromoPoints,
  StyledRateWrapper,
  StyledSignUpSummaryInfoWrapper,
  StyledTier,
  StyledValueContainer,
  StyledValueTitle,
} from "@portal/components/SignUpSummaryInfo/SignUpSummaryInfo.styled";
import { useSignUpSummaryPaymentDetails } from "@portal/components/SignUpSummaryInfo/useSignUpSummaryPaymentDetails";
import { ESI_ID_NOT_FOUND } from "@portal/constants/offer.constant";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ProductTier } from "@portal/models/ProductTier.model";
import { signUpStepPath } from "@portal/routes/routePaths";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { SignUpStateType } from "@portal/slices/signUpSlice";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useSelector } from "react-redux";

interface SignUpSummaryInfoProps {
  offerSnapshot: OfferSnapshot;
  paymentMethodDetails: PaymentMethodRetrieveType | undefined;
  selectedProductTiers: ProductTier[];
  signUpData: SignUpStateType;
}

const LabelCell: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <StyledLabelContainer>
      <StyledCell variant="body1" color="textSecondary">
        {children}
      </StyledCell>
    </StyledLabelContainer>
  );
};

const ValueCell: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <StyledValueContainer>
      <StyledCell variant="body1" component="div">
        {children}
      </StyledCell>
    </StyledValueContainer>
  );
};

interface DepositSectionProps {
  depositAlternativeAmount: string | null;
  depositAlternativeElected: boolean;
  depositAmount: number | null;
}

const DepositSection = (props: DepositSectionProps) => {
  const { depositAmount, depositAlternativeAmount, depositAlternativeElected } =
    props;

  const { translate } = useTranslations();

  const {
    tSignUpSummaryInfoDepositAlternativeLabel,
    tSignUpSummaryInfoDepositLabel,
  } = translate(signUpSummaryInfoTranslations);

  if (!depositAmount) {
    return null;
  }

  if (
    depositAlternativeElected &&
    depositAlternativeAmount !== "0" &&
    depositAlternativeAmount
  ) {
    return (
      <>
        <LabelCell>{tSignUpSummaryInfoDepositAlternativeLabel}</LabelCell>
        <ValueCell>{formatCurrency(depositAlternativeAmount)}</ValueCell>
      </>
    );
  }

  return (
    <>
      <LabelCell>{tSignUpSummaryInfoDepositLabel}</LabelCell>
      <ValueCell>{formatCurrency(depositAmount)}</ValueCell>
    </>
  );
};

interface SelectedProductTiersSectionProps {
  productTiers: ProductTier[];
}

const SelectedProductTiersSection = ({
  productTiers,
}: SelectedProductTiersSectionProps) => {
  const { translate, translateJsx } = useTranslations();

  const { tSignUpSummaryInfoAddOnsLabel } = translate(
    signUpSummaryInfoTranslations
  );

  return (
    <>
      <LabelCell>{tSignUpSummaryInfoAddOnsLabel}</LabelCell>
      <ValueCell>
        {productTiers.map((productTier) => {
          const { tSignUpSummaryInfoAddOnMonthlyRate } = translateJsx({
            tSignUpSummaryInfoAddOnMonthlyRate: {
              termMonths: productTier.prices[0].termLength,
            },
          });

          return (
            <StyledTier key={productTier.id}>
              <RhTypography component="span" fontWeight={FontWeight.Semibold}>
                {productTier.name}
              </RhTypography>
              <RhTypography component="span" variant="body1">
                {tSignUpSummaryInfoAddOnMonthlyRate}
              </RhTypography>
              <RhTypography component="span" variant="body1">
                {productTier.prices[0].formattedPricePerFrequency}
              </RhTypography>
            </StyledTier>
          );
        })}
      </ValueCell>
    </>
  );
};

export const SignUpSummaryInfo = (props: SignUpSummaryInfoProps) => {
  const {
    signUpData,
    offerSnapshot,
    selectedProductTiers,
    paymentMethodDetails,
  } = props;

  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  const {
    depositAlternativeAmount,
    depositAlternativeElected,
    depositAmount,
    dunsNumber,
    serviceStartDate,
    campaignPromo,
    firstName,
    lastName,
    serviceAddress,
    esiId,
    phone,
    email,
    autopay,
    invoiceByPrint,
  } = signUpData;

  const {
    formattedCurrencyBaseCharge,
    hasBaseChargeAmount,
    isSolarBuybackPriceDifferential,
    isTimeOfUse,
    price,
    promo,
    rhythmKwhRate,
    solarCreditKwhRate,
    tdspKwhRate,
    tdspMonthlyCharge,
    termMonths,
    title,
    isAutoPayPaperlessDiscountOffer,
    autopayPaperlessMonthlyDiscount,
    id: offerSnapshotId,
  } = offerSnapshot;

  const { translate, translateJsx } = useTranslations();

  const { paymentMethod, paymentMethodAccountNumberMask } =
    useSignUpSummaryPaymentDetails(paymentMethodDetails);

  const {
    tSignUpSummaryInfoPromoCodeApplied,
    tSignUpSummaryInfoPromoCodeLabel,
    tSignUpSummaryInfoStartDateLabel,
    tSignUpSummaryInfoPlanLabel,
    tSignUpSummaryInfoPaymentMethodLabel,
    tSignUpSummaryInfoAutoPayLabel,
    tSignUpSummaryInfoOnValue,
    tSignUpSummaryInfoOffValue,
    tSignUpSummaryInfoPaperlessLabel,
    tSignUpSummaryInfoBillingPreferencesLabel,
    tSignUpSummaryInfoContactInfoLabel,
    tSignUpSummaryInfoNameLabel,
    tSignUpSummaryInfoAddressLabel,
    tSignUpSummaryInfoUpdateBillingPreferencesLink,
    tSignUpSummaryInfoEsiIdLabel,
    tSignUpSummaryInfoContractDocumentsLabel,
    tSignUpSummaryInfoContractDocumentsTitle,
    tSignUpSummaryInfoTermsOfServiceLink,
    tSignUpSummaryInfoElectricityFactsLabelLink,
    tSignUpSummaryInfoYourRightsAsACustomerLink,
  } = translate(signUpSummaryInfoTranslations);

  const {
    tSignUpSummaryInfoPromoCodeValue,
    tSignUpSummaryInfoPlanMonthlyRate,
    tSignUpSummaryInfoTdspMonthlyCharge,
    tSignUpSummaryInfoTdspDeliveryCharge,
    tSignUpSummaryInfoBaseChargeLabel,
    tSignUpSummaryInfoSolarBuybackRate,
    tSignUpSummaryInfoAveragePriceRate,
    tSignUpSummaryInfoAveragePriceRatePaperless,
    tSignUpSummaryInfoEnergyChargeRate,
    tSignUpSummaryInfoAutoPayPaperlessDiscountWarning,
  } = translateJsx({
    tSignUpSummaryInfoAutoPayPaperlessDiscountWarning: {
      discountAmount: formatCurrency(autopayPaperlessMonthlyDiscount ?? ""),
    },
    tSignUpSummaryInfoAveragePriceRate: {
      price: formatDollarsToCents(price),
    },
    tSignUpSummaryInfoAveragePriceRatePaperless: {
      price: formatDollarsToCents(price),
    },
    tSignUpSummaryInfoBaseChargeLabel: {
      baseCharge: formattedCurrencyBaseCharge,
    },
    tSignUpSummaryInfoEnergyChargeRate: {
      energyCharge: formatDollarsToCents(rhythmKwhRate),
    },
    tSignUpSummaryInfoPlanMonthlyRate: {
      termMonths,
    },
    tSignUpSummaryInfoPromoCodeValue: {
      points: separateNumber((promo?.value || 0) * 10 ** 2),
      pointsChunk: (content: string) => (
        <StyledPromoPoints>{content}</StyledPromoPoints>
      ),
      value: formatCurrency(promo?.value || 0, 0),
    },
    tSignUpSummaryInfoSolarBuybackRate: {
      solarCreditKwhRate: formatDollarsToCents(solarCreditKwhRate),
    },
    tSignUpSummaryInfoTdspDeliveryCharge: {
      charge: formatDollarsToCents(tdspKwhRate),
      tdsp: DUNS_DISPLAY_NAME_MAPPING[dunsNumber],
    },
    tSignUpSummaryInfoTdspMonthlyCharge: {
      charge: formatCurrency(tdspMonthlyCharge),
      tdsp: DUNS_DISPLAY_NAME_MAPPING[dunsNumber],
    },
  });

  const hasPromo = Boolean(promo || campaignPromo);

  const showPaperlessAutoPayWarning =
    isAutoPayPaperlessDiscountOffer && (!autopay || invoiceByPrint);

  return (
    <StyledSignUpSummaryInfoWrapper>
      <LabelCell>{tSignUpSummaryInfoNameLabel}</LabelCell>
      <ValueCell>{`${firstName} ${lastName}`}</ValueCell>

      <LabelCell>{tSignUpSummaryInfoAddressLabel}</LabelCell>
      <ValueCell>
        <div>
          {`${serviceAddress.addressLine1} ${serviceAddress.unitNumber ?? ""}`}
        </div>
        <div>{`${serviceAddress.city}, ${serviceAddress.state}`}</div>
        <div>{serviceAddress.zipCode}</div>
      </ValueCell>

      <LabelCell>{tSignUpSummaryInfoEsiIdLabel}</LabelCell>
      <ValueCell>
        {esiId === ESI_ID_NOT_FOUND ? NOT_APPLICABLE_ABBREVIATION : esiId}
      </ValueCell>

      <LabelCell>{tSignUpSummaryInfoContactInfoLabel}</LabelCell>
      <ValueCell>
        <div>{formatPhoneNumber(phone)}</div>
        <div>{email}</div>
      </ValueCell>

      <LabelCell>{tSignUpSummaryInfoBillingPreferencesLabel} </LabelCell>
      <ValueCell>
        <StyledBillingPreference>
          <span>{tSignUpSummaryInfoPaperlessLabel}</span>
          <StyledBillingPreferenceValue>
            {!invoiceByPrint
              ? tSignUpSummaryInfoOnValue
              : tSignUpSummaryInfoOffValue}
          </StyledBillingPreferenceValue>
        </StyledBillingPreference>
        <StyledBillingPreference>
          <span>{tSignUpSummaryInfoAutoPayLabel} </span>
          <StyledBillingPreferenceValue>
            {autopay ? tSignUpSummaryInfoOnValue : tSignUpSummaryInfoOffValue}
          </StyledBillingPreferenceValue>
        </StyledBillingPreference>

        {showPaperlessAutoPayWarning ? (
          <AutopayPaperlessDiscountContainer>
            <StyledValueTitle
              component="span"
              fontWeight={FontWeight.Bold}
              variant="h3"
            >
              {tSignUpSummaryInfoAutoPayPaperlessDiscountWarning}
            </StyledValueTitle>
            <RhRouterLink to={signUpStepPath("billing-preferences")}>
              {tSignUpSummaryInfoUpdateBillingPreferencesLink}
            </RhRouterLink>
          </AutopayPaperlessDiscountContainer>
        ) : null}
      </ValueCell>

      <LabelCell>{tSignUpSummaryInfoPaymentMethodLabel}</LabelCell>
      <ValueCell>
        <div>{paymentMethod}</div>
        <div>{paymentMethodAccountNumberMask}</div>
      </ValueCell>

      <DepositSection
        depositAlternativeAmount={depositAlternativeAmount}
        depositAlternativeElected={depositAlternativeElected}
        depositAmount={depositAmount}
      />

      <LabelCell>{tSignUpSummaryInfoPlanLabel}</LabelCell>

      <ValueCell>
        <StyledPlanDetailWrapper>
          <StyledPlanDetail>
            <StyledValueTitle
              component="span"
              fontWeight={FontWeight.Bold}
              variant="h3"
            >
              {title}
            </StyledValueTitle>
            <RhTypography
              component="span"
              variant="body1"
              color="textSecondary"
            >
              {tSignUpSummaryInfoPlanMonthlyRate}
            </RhTypography>
          </StyledPlanDetail>
          {isTimeOfUse ? (
            <PriceTierSnapshotsSummary offerSnapshot={offerSnapshot} />
          ) : (
            <RhTypography component="span" variant="body1">
              {tSignUpSummaryInfoEnergyChargeRate}
            </RhTypography>
          )}

          {isSolarBuybackPriceDifferential && (
            <RhTypography component="span" variant="body1">
              {tSignUpSummaryInfoSolarBuybackRate}
            </RhTypography>
          )}
          {hasBaseChargeAmount && (
            <RhTypography component="span" variant="body1">
              {tSignUpSummaryInfoBaseChargeLabel}
            </RhTypography>
          )}
          <RhTypography component="span" variant="body1">
            {tSignUpSummaryInfoTdspDeliveryCharge}
          </RhTypography>
          <RhTypography component="span" variant="body1">
            {tSignUpSummaryInfoTdspMonthlyCharge}
          </RhTypography>
          <StyledRateWrapper>
            <RhTypography
              component="b"
              variant="body1"
              fontWeight={FontWeight.Semibold}
            >
              {isAutoPayPaperlessDiscountOffer
                ? tSignUpSummaryInfoAveragePriceRatePaperless
                : tSignUpSummaryInfoAveragePriceRate}
            </RhTypography>
          </StyledRateWrapper>
        </StyledPlanDetailWrapper>
      </ValueCell>

      {selectedProductTiers.length > 0 && (
        <SelectedProductTiersSection productTiers={selectedProductTiers} />
      )}

      <LabelCell>{tSignUpSummaryInfoStartDateLabel}</LabelCell>
      <ValueCell>{dayjs(serviceStartDate).format("MMMM DD, YYYY")}</ValueCell>

      <LabelCell>{tSignUpSummaryInfoContractDocumentsLabel}</LabelCell>

      <ValueCell>
        <ContractDocumentsContainer>
          <StyledValueTitle
            component="span"
            fontWeight={FontWeight.Bold}
            variant="h3"
          >
            {tSignUpSummaryInfoContractDocumentsTitle}
          </StyledValueTitle>
          <TOSLink tosUrl={offerSnapshot.rhythmTosLink(locale)}>
            {tSignUpSummaryInfoTermsOfServiceLink}
          </TOSLink>
          <YRACLink locale={locale}>
            {tSignUpSummaryInfoYourRightsAsACustomerLink}
          </YRACLink>
          <EFLLink offerId={offerSnapshotId} locale={locale}>
            {tSignUpSummaryInfoElectricityFactsLabelLink}
          </EFLLink>
        </ContractDocumentsContainer>
      </ValueCell>

      {hasPromo && (
        <>
          <LabelCell>{tSignUpSummaryInfoPromoCodeLabel}</LabelCell>
          <ValueCell>
            <RhTypography color="textSecondary" variant="body2">
              {tSignUpSummaryInfoPromoCodeApplied}
            </RhTypography>
            {promo?.code && (
              <RhTypography color="textSecondary" variant="body2">
                {tSignUpSummaryInfoPromoCodeValue}
              </RhTypography>
            )}
          </ValueCell>
        </>
      )}
    </StyledSignUpSummaryInfoWrapper>
  );
};
