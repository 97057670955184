export interface OfferSnapshotsSearchOptions {
  campaignSlug?: string;
  dunsNumber?: string;
  featured?: boolean;
  limit?: number;
  offerLabels?: OfferSnapshotOfferLabels[];
  offset?: number;
  ordering?: OfferSnapshotOrderOptions;
  termMonths?: OfferSnapshotTermLengths[];
  zipCode?: string;
}

export enum OfferSnapshotTermLengths {
  ONE = "1",
  THIRTY_SIX = "36",
  TWELVE = "12",
  TWENTY_FOUR = "24",
}
export enum OfferSnapshotOfferLabels {
  EV = "EV",
  // Featured is technically not an offer label but given that BE will accept it as one, for FE purposes it is
  FEATURED = "featured",
  NO_FRILLS = "No-frills",
  SBB_PRICE_DIFFERENTIAL = "SBB Price Differential",
  SIMPLISAFE = "SimpliSafe",
  SIMPLISAFE_OUTDOOR = "SimpliSafe-Outdoor",
  SIMPLY_DRIVE = "Simply Drive",
  SOLAR = "Solar",
  TIME_OF_USE = "Time-of-Use",
  TWO_MONTHS_FREE = "2 Months Free",
  WIND = "Wind",
}

export enum OfferSnapshotOrderOptions {
  PRICE_ASCENDING = "price",
  PRICE_DESCENDING = "-price",
  PRIORITY_ASCENDING = "priority",
  PRIORITY_DESCENDING = "-priority",
  TERM_MONTHS_ASCENDING = "term_months",
  TERM_MONTHS_DESCENDING = "-term_months",
}
