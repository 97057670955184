import { ReactComponent as RhythmLogoDarkPurple } from "@common/images/RhythmLogoDarkPurple.svg";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { competitorPlanComparisonPlansModalTranslations } from "@portal/components/CompetitorPlanComparisonPlansModal/CompetitorPlanComparisonPlansModal.en.i18n";
import {
  PlanName,
  PlanPrice,
  PlanPriceContainer,
  PlanPricePerKwh,
  SelectedPlanContainer,
  SelectedPlanContent,
} from "@portal/components/CompetitorPlanComparisonPlansModal/CompetitorPlanComparisonPlansModal.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface SelectedPlanProps {
  offerSnapshot: OfferSnapshot;
  onSelectPlanClick: (OfferSnapshot: OfferSnapshot) => void;
}
export const SelectedPlan = (props: SelectedPlanProps) => {
  const { offerSnapshot, onSelectPlanClick } = props;

  const { translate } = useTranslations();

  const {
    tCompetitorPlanComparisonPlansModalPerKwh,
    tCompetitorPlanComparisonPlansModalEnrollNow,
  } = translate(competitorPlanComparisonPlansModalTranslations);

  return (
    <SelectedPlanContainer>
      <RhythmLogoDarkPurple />

      <SelectedPlanContent>
        <PlanName>{offerSnapshot.title}</PlanName>

        <PlanPriceContainer>
          <PlanPrice>
            {formatDollarsToCents(
              offerSnapshot.solarEligible
                ? offerSnapshot.rhythmKwhRate
                : offerSnapshot.price2000Kwh
            )}
          </PlanPrice>{" "}
          <PlanPricePerKwh>
            {tCompetitorPlanComparisonPlansModalPerKwh}
          </PlanPricePerKwh>
        </PlanPriceContainer>
      </SelectedPlanContent>

      <PlanPriceContainer>
        <PlanPrice>
          {formatDollarsToCents(
            offerSnapshot.solarEligible
              ? offerSnapshot.rhythmKwhRate
              : offerSnapshot.price2000Kwh
          )}
        </PlanPrice>{" "}
        <PlanPricePerKwh>
          {tCompetitorPlanComparisonPlansModalPerKwh}
        </PlanPricePerKwh>
      </PlanPriceContainer>

      <RhButton
        data-tracking-click={{
          event: "Competitor Plan Comparison Modal plan selected",
          offerSnapshotUuid: offerSnapshot.uuid,
        }}
        color="primary"
        size="small"
        onClick={() => onSelectPlanClick(offerSnapshot)}
      >
        {tCompetitorPlanComparisonPlansModalEnrollNow}
      </RhButton>
    </SelectedPlanContainer>
  );
};
