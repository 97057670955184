import {
  EnergyTexasTheme,
  RhythmTheme,
} from "@design-system/brandTheme/brandTheme";

export const useBrandTheme = () => {
  if (import.meta.env.VITE_BRAND === "energy-texas") {
    return EnergyTexasTheme;
  }

  return RhythmTheme;
};
