import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const EnergyUsageChartMissingContainer = styled.div`
  background-color: ${grey[50]};
  border-radius: 6px;
  height: 100%;
  margin: ${rhSpacingPx(2.5)};
  padding: ${rhSpacingPx(8)} ${rhSpacingPx(3)};
  text-align: center;
`;

export const EnergyUsageChartMissingText = styled(RhTypography)`
  &.MuiTypography-root {
    padding-top: ${rhSpacingPx(0.5)};
  }
`;
