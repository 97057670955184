import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsTranslations } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQs.en.i18n";
import { OfferSnapshotFAQsContainer } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQs.styled";
import { OfferSnapshotFAQsAmazonThermostat } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsAmazonThermostat/OfferSnapshotFAQsAmazonThermostat";
import { OfferSnapshotFAQsDevices } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsDevices/OfferSnapshotFAQsDevices";
import { OfferSnapshotFAQsDisclaimer } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsDisclaimer/OfferSnapshotFAQsDisclaimer";
import { OfferSnapshotFAQsDiscountPlan } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsDiscountPlan/OfferSnapshotFAQsDiscountPlan";
import { OfferSnapshotFAQsGeneral } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsGeneral/OfferSnapshotFAQsGeneral";
import { OfferSnapshotFAQsSimpliSafe } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsSimpliSafe/OfferSnapshotFAQsSimpliSafe";
import { OfferSnapshotFAQsSolar } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsSolar/OfferSnapshotFAQsSolar";
import { OfferSnapshotFAQsTimeOfUse } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTimeOfUse/OfferSnapshotFAQsTimeOfUse";
import { OfferSnapshotFAQsTwoMonthsFree } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTwoMonthsFree/OfferSnapshotFAQsTwoMonthsFree";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface OfferSnapshotFAQsProps {
  className?: string;
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotFAQs = (props: OfferSnapshotFAQsProps) => {
  const { offerSnapshot, className } = props;
  const { translate } = useTranslations();
  const { tOfferSnapshotFAQsTitle } = translate(offerSnapshotFAQsTranslations);

  const {
    autopayPaperlessMonthlyDiscount,
    isAutoPayPaperlessDiscountOffer,
    isSimpliSafeOffer,
    isSimpliSafeOutdoorOffer,
    isThermostatOffer,
    isTimeOfUse,
    isTwoFreeMonthsOffer,
    solarEligible,
    isThermostatIncluded,
  } = offerSnapshot;

  if (solarEligible) {
    return (
      <OfferSnapshotFAQsContainer className={className}>
        <RhTypography variant="h2">{tOfferSnapshotFAQsTitle}</RhTypography>
        <OfferSnapshotFAQsSolar offerSnapshot={offerSnapshot} />
      </OfferSnapshotFAQsContainer>
    );
  }

  return (
    <OfferSnapshotFAQsContainer className={className}>
      <RhTypography variant="h2">{tOfferSnapshotFAQsTitle}</RhTypography>

      {isThermostatIncluded ? <OfferSnapshotFAQsAmazonThermostat /> : null}
      {isThermostatOffer ? (
        <OfferSnapshotFAQsDevices offerSnapshot={offerSnapshot} />
      ) : null}

      {isAutoPayPaperlessDiscountOffer ? (
        <OfferSnapshotFAQsDiscountPlan
          monthlyDiscountAmount={autopayPaperlessMonthlyDiscount ?? ""}
        />
      ) : null}

      {isSimpliSafeOffer || isSimpliSafeOutdoorOffer ? (
        <OfferSnapshotFAQsSimpliSafe />
      ) : null}
      {isTwoFreeMonthsOffer ? <OfferSnapshotFAQsTwoMonthsFree /> : null}
      {isTimeOfUse ? <OfferSnapshotFAQsTimeOfUse /> : null}

      <OfferSnapshotFAQsGeneral offerSnapshot={offerSnapshot} />

      <OfferSnapshotFAQsDisclaimer offerSnapshot={offerSnapshot} />
    </OfferSnapshotFAQsContainer>
  );
};
