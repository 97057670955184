import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { useBrandTheme } from "@design-system/brandTheme/useBrandTheme";
import { buildChartData } from "@portal/components/CompetitorPlanComparisonChart/buildChartData";
import { buildChartOptions } from "@portal/components/CompetitorPlanComparisonChart/buildChartOptions";
import {
  drawLinePlugin,
  drawTextPlugin,
  imageTickPlugin,
} from "@portal/components/CompetitorPlanComparisonChart/chartJsPlugins";
import { competitorPlanComparisonChartTranslations } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.en.i18n";
import { ChartContainer } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.styled";
import { GroupedCompetitorPlans } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { useTranslations } from "@portal/hooks/useTranslations";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect, useMemo, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

ChartJS.register(LinearScale, CategoryScale, BarElement, Legend, Tooltip);

export const CompetitorPlanChart = ({
  competitorPlans,
  offerSnapshot,
}: {
  competitorPlans: GroupedCompetitorPlans[];
  offerSnapshot: OfferSnapshot;
}) => {
  const theme = useBrandTheme();
  const { translate } = useTranslations();
  const ref = useRef<ChartJSOrUndefined<"bar", GroupedCompetitorPlans[]>>();

  const {
    tCompetitorPlanComparisonChartPerKwh,
    tCompetitorPlanComparisonChartAnnualSavings,
    tCompetitorPlanComparisonChartWithRhythm,
  } = translate(competitorPlanComparisonChartTranslations);

  const chartData = useMemo(() => {
    return buildChartData({
      competitorPlans,
      offerSnapshot,
      theme,
    });
  }, [competitorPlans, offerSnapshot, theme]);

  const chartOptions = useMemo(() => {
    return buildChartOptions({
      offerSnapshot,
      tooltipText: [
        tCompetitorPlanComparisonChartAnnualSavings,
        tCompetitorPlanComparisonChartWithRhythm,
      ],
    });
  }, [offerSnapshot]);

  useEffect(() => {
    if (ref.current) {
      ref.current.data = chartData;
      // If chartData or chartOptions change we need to force an update of the chart
      ref.current.update("resize");
    }
  }, [chartData, chartOptions, ref]);

  return (
    <ChartContainer className="chart-container">
      <Bar
        ref={ref}
        updateMode="resize"
        data={chartData}
        options={chartOptions}
        plugins={[
          drawLinePlugin({
            lineValue: offerSnapshot.displayPrice(),
          }),
          drawTextPlugin({
            perKwhLabel: tCompetitorPlanComparisonChartPerKwh,
          }),
          imageTickPlugin(),
        ]}
      />
    </ChartContainer>
  );
};
