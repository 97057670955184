import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2.5)};
  justify-content: center;
  padding: ${rhSpacingPx(1)};
  text-align: center;
  width: 100%;

  @media screen and (min-width: 600px) {
    padding: ${rhSpacingPx(1)} ${rhSpacingPx(3)};
  }
`;

export const StyledTitle = styled(RhTypography).attrs({ variant: "h2" })`
  text-align: center;
  text-wrap: balance;
`;

export const StyledTextButton = styled.button`
  background: none;
  border: none;
  color: ${grey[700]};
  cursor: pointer;
  padding: 0;
  text-align: center;
  text-decoration: underline;
`;

export const StyledBrandIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  width: 100%;
`;

export const StyledBrandIcon = styled.div`
  align-items: center;
  border: 1px solid ${grey[50]};
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 200px;
  min-height: 150px;

  > * {
    margin: auto;
  }
`;

export const StyledThermostatRequirementsList = styled.ol`
  font-size: 16px;
  list-style: decimal;
  list-style-position: inside;
`;
