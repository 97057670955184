import { DefaultTheme } from "styled-components";

export const RhythmTheme: DefaultTheme = {
  colors: {
    black: "#000000",
    disabled: {
      background: "#EDEBF0",
      border: "#EDEBF0",
      hover: "#777380",
      text: "#777380",
    },
    error: "#C24842",
    grey: {
      100: "#EDEBF0",
      200: "#CCC9D1",
      400: "#777380",
      50: "#F5F5F7",
      500: "#918D99",
      900: "#171619",
    },
    info: "#03a9f4",
    primary: {
      dark: "#290063",
      light: "#ECEBFA",
      lightest: "#F6F5FC",
      main: "#4500A5",
      medium: "#8876DB",
    },
    secondary: {
      dark: "#5A1F99",
      light: "#C085FF",
      lightest: "#FAF5FF",
      main: "#9633FF",
      medium: "#B4B0FF",
    },
    success: "#59C28A",
    white: "#FFFFFF",
  },
} as const;

export const EnergyTexasTheme: DefaultTheme = {
  colors: {
    black: "#000000",
    disabled: {
      background: "#EDF0F2",
      border: "#EDF0F2",
      hover: "#77808A",
      text: "#77808A",
    },
    error: "#C80000",
    grey: {
      100: "#EDF0F2",
      200: "#D3D6D8",
      400: "#77808A",
      50: "#F5F6F7",
      500: "#ABB4BC",
      900: "#17191E",
    },
    info: "#03a9f4",
    primary: {
      dark: "#0f2347",
      light: "#edf4f7",
      lightest: "", // TODO: Confirm this color
      main: "#00afef",
      medium: "", // TODO: Confirm this color
    },
    secondary: {
      dark: "#102247",
      light: "#00afef",
      lightest: "", // TODO: Confirm this color
      main: "#0f2347",
      medium: "", // TODO: Confirm this color
    },
    success: "#59C28A",
    white: "#FFFFFF",
  },
};
