import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { CompetitorPlanComparison } from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { useHandleEnrollNowClick } from "@portal/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { useTranslations } from "@portal/hooks/useTranslations";
import { enrollOffersPageRepPriceComparisonChartTranslations } from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPageRepPriceComparisonChart/EnrollOffersPageRepPriceComparisonChart.en.i18n";
import { EnrollOffersPageRepPriceComparisonChartContainer } from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPageRepPriceComparisonChart/EnrollOffersPageRepPriceComparisonChart.styled";
import React, { useCallback } from "react";

interface EnrollOffersPageRepPriceComparisonChartProps {
  estimatedMonthlyUsage: number;
  id: string;
  offerSnapshots: OfferSnapshot[];
}

export const EnrollOffersPageRepPriceComparisonChart = (
  props: EnrollOffersPageRepPriceComparisonChartProps
) => {
  const { estimatedMonthlyUsage, offerSnapshots, id } = props;

  const { addModal } = useModals();
  const handleEnrollNowClick = useHandleEnrollNowClick();
  const { translate } = useTranslations();

  const { tEnrollOffersPageRepPriceComparisonChartSelectPlan } = translate(
    enrollOffersPageRepPriceComparisonChartTranslations
  );

  const handleSelectPlanClick = useCallback(
    (offerSnapshot: OfferSnapshot) => {
      if (offerSnapshot.isThermostatOffer) {
        return addModal({
          offerSnapshotThermostatRequired: {
            offerSnapshot,
            onEnrollClick: () =>
              handleEnrollNowClick({
                estimatedMonthlyUsage,
                offerSnapshot,
              }),
          },
        });
      }

      return handleEnrollNowClick({
        estimatedMonthlyUsage,
        offerSnapshot,
      });
    },
    [estimatedMonthlyUsage, handleEnrollNowClick, addModal]
  );

  if (offerSnapshots.length === 0) {
    return null;
  }

  const handleLearnMoreClick = (offerSnapshot: OfferSnapshot) => {
    addModal({
      offerSnapshotLearnMore: {
        offerSnapshotUUID: offerSnapshot.uuid,
        onEnrollClick: () => {
          handleEnrollNowClick({
            estimatedMonthlyUsage,
            offerSnapshot,
          });
        },
        selectPlanCtaText: tEnrollOffersPageRepPriceComparisonChartSelectPlan,
      },
    });
  };

  return (
    <EnrollOffersPageRepPriceComparisonChartContainer>
      <CompetitorPlanComparison
        miniCardSubmitText={tEnrollOffersPageRepPriceComparisonChartSelectPlan}
        onLearnMoreClick={handleLearnMoreClick}
        onSelectPlanClick={(offerSnapshot) =>
          handleSelectPlanClick(offerSnapshot)
        }
        offerSnapshots={offerSnapshots}
        id={id}
      />
    </EnrollOffersPageRepPriceComparisonChartContainer>
  );
};
