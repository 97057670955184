import { createGlobalStyle, css } from "styled-components";

// CSS Color Properties
//
// This is the custom property used in both the var and defined in the :root of GlobalBrandedThemeStyles.
enum CSSColorProperties {
  COLOR_BLACK = "--color-black",
  COLOR_DISABLED_BACKGROUND = "--color-disabled-background",
  COLOR_DISABLED_BORDER = "--color-disabled-border",
  COLOR_DISABLED_HOVER = "--color-disabled-hover",
  COLOR_DISABLED_TEXT = "--color-disabled-text",
  COLOR_ERROR = "--color-error",
  COLOR_GREY_100 = "--color-grey-100",
  COLOR_GREY_200 = "--color-grey-200",
  COLOR_GREY_400 = "--color-grey-400",
  COLOR_GREY_50 = "--color-grey-50",
  COLOR_GREY_500 = "--color-grey-500",
  COLOR_GREY_900 = "--color-grey-900",
  COLOR_INFO = "--color-info",
  COLOR_PRIMARY_DARK = "--color-primary-dark",
  COLOR_PRIMARY_LIGHT = "--color-primary-light",
  COLOR_PRIMARY_LIGHTEST = "--color-primary-lightest",
  COLOR_PRIMARY_MAIN = "--color-primary-main",
  COLOR_PRIMARY_MEDIUM = "--color-primary-medium",
  COLOR_SECONDARY_DARK = "--color-secondary-dark",
  COLOR_SECONDARY_LIGHT = "--color-secondary-light",
  COLOR_SECONDARY_LIGHTEST = "--color-secondary-lightest",
  COLOR_SECONDARY_MAIN = "--color-secondary-main",
  COLOR_SECONDARY_MEDIUM = "--color-secondary-medium",
  COLOR_SUCCESS = "--color-success",
  COLOR_WHITE = "--color-white",
}

// CSS Variables
//
// This is what will be used in the codebase to reference custom properties.
export enum CSSVariables {
  COLOR_BLACK = `var(${CSSColorProperties.COLOR_BLACK})`,
  COLOR_DISABLED_BACKGROUND = `var(${CSSColorProperties.COLOR_DISABLED_BACKGROUND})`,
  COLOR_DISABLED_BORDER = `var(${CSSColorProperties.COLOR_DISABLED_BORDER})`,
  COLOR_DISABLED_HOVER = `var(${CSSColorProperties.COLOR_DISABLED_HOVER})`,
  COLOR_DISABLED_TEXT = `var(${CSSColorProperties.COLOR_DISABLED_TEXT})`,
  COLOR_ERROR = `var(${CSSColorProperties.COLOR_ERROR})`,
  COLOR_GREY_100 = `var(${CSSColorProperties.COLOR_GREY_100})`,
  COLOR_GREY_200 = `var(${CSSColorProperties.COLOR_GREY_200})`,
  COLOR_GREY_400 = `var(${CSSColorProperties.COLOR_GREY_400})`,
  COLOR_GREY_50 = `var(${CSSColorProperties.COLOR_GREY_50})`,
  COLOR_GREY_500 = `var(${CSSColorProperties.COLOR_GREY_500})`,
  COLOR_GREY_900 = `var(${CSSColorProperties.COLOR_GREY_900})`,
  COLOR_INFO = `var(${CSSColorProperties.COLOR_INFO})`,
  COLOR_PRIMARY_DARK = `var(${CSSColorProperties.COLOR_PRIMARY_DARK})`,
  COLOR_PRIMARY_LIGHT = `var(${CSSColorProperties.COLOR_PRIMARY_LIGHT})`,
  COLOR_PRIMARY_LIGHTEST = `var(${CSSColorProperties.COLOR_PRIMARY_LIGHTEST})`,
  COLOR_PRIMARY_MAIN = `var(${CSSColorProperties.COLOR_PRIMARY_MAIN})`,
  COLOR_PRIMARY_MEDIUM = `var(${CSSColorProperties.COLOR_PRIMARY_MEDIUM})`,
  COLOR_SECONDARY_DARK = `var(${CSSColorProperties.COLOR_SECONDARY_DARK})`,
  COLOR_SECONDARY_LIGHT = `var(${CSSColorProperties.COLOR_SECONDARY_LIGHT})`,
  COLOR_SECONDARY_LIGHTEST = `var(${CSSColorProperties.COLOR_SECONDARY_LIGHTEST})`,
  COLOR_SECONDARY_MAIN = `var(${CSSColorProperties.COLOR_SECONDARY_MAIN})`,
  COLOR_SECONDARY_MEDIUM = `var(${CSSColorProperties.COLOR_SECONDARY_MEDIUM})`,
  COLOR_SUCCESS = `var(${CSSColorProperties.COLOR_SUCCESS})`,
  COLOR_WHITE = `var(${CSSColorProperties.COLOR_WHITE})`,
}

// Global Styles
//
// This is where the CSS Variables are defined using their corresponding theme values.
export const GlobalBrandedThemeStyles = createGlobalStyle` 
  :root {    
    /* Theme custom vars here */
    ${({ theme }) => css`
      ${CSSColorProperties.COLOR_BLACK}: ${theme.colors.black};

      ${CSSColorProperties.COLOR_DISABLED_BACKGROUND}: ${theme.colors.disabled
        .background};
      ${CSSColorProperties.COLOR_DISABLED_BORDER}: ${theme.colors.disabled
        .border};
      ${CSSColorProperties.COLOR_DISABLED_HOVER}: ${theme.colors.disabled
        .hover};
      ${CSSColorProperties.COLOR_DISABLED_TEXT}: ${theme.colors.disabled.text};
      ${CSSColorProperties.COLOR_ERROR}: ${theme.colors.error};
      ${CSSColorProperties.COLOR_GREY_100}: ${theme.colors.grey[100]};
      ${CSSColorProperties.COLOR_GREY_200}: ${theme.colors.grey[200]};
      ${CSSColorProperties.COLOR_GREY_400}: ${theme.colors.grey[400]};
      ${CSSColorProperties.COLOR_GREY_500}: ${theme.colors.grey[500]};
      ${CSSColorProperties.COLOR_GREY_50}: ${theme.colors.grey[50]};
      ${CSSColorProperties.COLOR_GREY_900}: ${theme.colors.grey[900]};
      ${CSSColorProperties.COLOR_INFO}: ${theme.colors.info};
      ${CSSColorProperties.COLOR_PRIMARY_DARK}: ${theme.colors.primary.dark};
      ${CSSColorProperties.COLOR_PRIMARY_LIGHT}: ${theme.colors.primary.light};
      ${CSSColorProperties.COLOR_PRIMARY_LIGHTEST}: ${theme.colors.primary
        .lightest};
      ${CSSColorProperties.COLOR_PRIMARY_MAIN}: ${theme.colors.primary.main};
      ${CSSColorProperties.COLOR_PRIMARY_MEDIUM}: ${theme.colors.primary
        .medium};
      ${CSSColorProperties.COLOR_SECONDARY_DARK}: ${theme.colors.secondary
        .dark};
      ${CSSColorProperties.COLOR_SECONDARY_LIGHT}: ${theme.colors.secondary
        .light};
      ${CSSColorProperties.COLOR_SECONDARY_LIGHTEST}: ${theme.colors.secondary
        .lightest};
      ${CSSColorProperties.COLOR_SECONDARY_MAIN}: ${theme.colors.secondary
        .main};
      ${CSSColorProperties.COLOR_SECONDARY_MEDIUM}: ${theme.colors.secondary
        .medium};
      ${CSSColorProperties.COLOR_SUCCESS}: ${theme.colors.success};
      ${CSSColorProperties.COLOR_WHITE}: ${theme.colors.white};
    `}
	}
`;
