import {
  DialogFadeIn,
  DialogSlideDown,
  DialogSlideInLeft,
  DialogSlideInRight,
  DialogSlideUp,
} from "@design-system/components/RhModal/RhModal.animations";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { orange, purple } from "@design-system/theme/palette.colors";
import { theme } from "@design-system/theme/theme.styled";
import styled, { css } from "styled-components";

export enum ModalWidths {
  auto = "auto",
  lg = "60rem",
  md = "44rem",
  sm = "36rem",
  xs = "24rem",
}

export enum ModalPlacement {
  Bottom = "Bottom - Slide Up",
  Left = "Left - Slide In Right",
  PageCenter = "Page Centered",
  Right = "Right - Slide In Left",
  Top = "Top - Slide Down",
}

const RhModalPositioning = styled.div`
  bottom: 0;
  left: 0;
  padding: ${theme.ui.spacing.sm};
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
`;

type RhModalPositioningProps = {
  $placement?: ModalPlacement;
  $showPortalFooter?: boolean;
  $showPortalHeader?: boolean;
};

export const RhModalBackground = styled(
  RhModalPositioning
)<RhModalPositioningProps>`
  background-color: rgba(0, 0, 0, 0.15);
  bottom: ${({ $showPortalFooter }) => ($showPortalFooter ? "75px" : 0)};
  top: ${({ $showPortalHeader }) => ($showPortalHeader ? "60px" : 0)};
  z-index: 0;
`;

export const RhModalCloseButton = styled.button`
  --svgSize: 0.75rem;

  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${theme.palette.grey[300]};
  cursor: pointer;
  height: auto;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: var(--svgSize);

  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

interface RhModalDialogProps {
  $noPadding?: boolean;
  $overflowVisible?: boolean;
  $size?: ModalWidths;
  $withGradientBorder?: boolean;
}

export const RhModalDialog = styled.div<RhModalDialogProps>`
  ${({ $size = ModalWidths.md, $withGradientBorder }) => css`
    background: ${$withGradientBorder
      ? `linear-gradient(${orange[400]}, ${purple.main})`
      : theme.palette.white};
    border-radius: ${theme.ui.borders.radius};
    padding: 2px;
    width: ${$size};
  `}
  animation: ${DialogFadeIn} 0.1s linear 1;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 100%;

  opacity: 1;
  position: relative;
  transform-origin: center center;

  z-index: 1;
`;

export const RhModalDialogInner = styled.div<RhModalDialogProps>`
  ${({ $noPadding, $size = ModalWidths.md }) => css`
    background: ${theme.palette.white};
    border-radius: 6px;
    padding: ${$noPadding ? 0 : theme.ui.spacing.sm};
    width: ${$size};
  `}
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow-y: ${({ $overflowVisible }) =>
    $overflowVisible ? "visible" : "auto"};
  position: relative;

  width: 100%;
`;

// MuiAppBar z-index is 1100
export const RhModalPageContainer = styled(
  RhModalPositioning
)<RhModalPositioningProps>`
  align-items: center;
  bottom: ${({ $showPortalFooter }) => ($showPortalFooter ? "75px" : 0)};
  display: flex;

  justify-content: center;
  top: 0;
  z-index: 1101;

  > ${RhModalDialog} {
    margin: auto;
  }

  ${({ $placement }) =>
    $placement === ModalPlacement.Bottom &&
    css`
      align-items: flex-end;
      padding: 0;

      > ${RhModalDialog} {
        animation: ${DialogSlideUp} 0.25s ease-in-out 1;
        border-radius: 10px 10px 0 0;
        margin: 0 auto;
        width: 100%;
      }
    `}

  ${({ $placement }) =>
    $placement === ModalPlacement.Top &&
    css`
      align-items: flex-start;
      padding: 0;

      > ${RhModalDialog} {
        animation: ${DialogSlideDown} 0.25s ease-in-out 1;
        border-radius: 0;
        margin: 0 auto;
        width: 100%;
      }
    `}

  ${({ $placement }) =>
    $placement === ModalPlacement.Right &&
    css`
      justify-content: flex-end;
      padding: 0;

      > ${RhModalDialog} {
        animation: ${DialogSlideInLeft} 0.25s ease-in-out 1;
        border-radius: 0;
        height: 100%;
        margin: 0;
      }
    `}

    ${({ $placement }) =>
    $placement === ModalPlacement.Left &&
    css`
      justify-content: flex-start;
      padding: 0;

      > ${RhModalDialog} {
        animation: ${DialogSlideInRight} 0.25s ease-in-out 1;
        border-radius: 0;
        height: 100%;
        margin: 0;
      }
    `}
`;

export const RhModalTitle = styled.h3`
  font-size: 1rem;
  font-weight: ${FontWeight.Regular};
  line-height: 1.1;
  margin: 0;

  @media screen and (min-width: 36rem) {
    font-size: 1.35rem;
  }
`;
